import IChannel from "../types/channel";

class ChannelsService {
  static async getChannels(): Promise<IChannel[]> {
    const res = await fetch("/configs/channels.json", {
      cache: "no-store",
    });
    const data: IChannel[] = await res.json();
    return data;
  }

  static async getChannel(channelId: number): Promise<IChannel | null> {
    const channels = await ChannelsService.getChannels();
    const filteredChannels = channels.filter((channel) => channel.id === channelId);
    if (filteredChannels.length < 1) {
      return null;
    }

    return filteredChannels[0];
  }
}

export default ChannelsService;
