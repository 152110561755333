import styled from "styled-components";
import pxIntoRem from "../../utils/pxIntoRem";

const ContainerBlock = styled.div`
  margin: 0 auto;
  max-width: 405px;
  width: 100%;
  height: auto;
  @media (max-width: 420px) {
    padding: 0px ${pxIntoRem(25)};
  }
`;

export { ContainerBlock };
