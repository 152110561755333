import { FC, useEffect, useRef, useState } from "react";
import { PlayButtonBlock, PlayButtonImage } from "./PlayButtonStyles";

interface IPlayButton {
  onClick?: () => any;
  isPlaying: boolean;
  color: string
}

const PlayButton: FC<IPlayButton> = ({ onClick, isPlaying, color }) => {
  const [currentSrc, setCurrentSrc] = useState("");

  const playImage = useRef<HTMLImageElement | null>(null);
  const stopImage = useRef<HTMLImageElement | null>(null);

  useEffect(() => {
    const playImageObj = new Image();
    playImageObj.src = "/images/start.png";
    playImage.current = playImageObj;

    const stopImageObj = new Image();
    stopImageObj.src = "/images/stop.png";
    stopImage.current = stopImageObj;

    setCurrentSrc(isPlaying ? stopImage.current.src : playImage.current.src);
  }, []);

  useEffect(() => {
    if (!playImage.current || !stopImage.current) {
      return;
    }

    setCurrentSrc(isPlaying ? stopImage.current.src : playImage.current.src);
  }, [isPlaying]);

  return (
    <PlayButtonBlock onClick={onClick} color={color}>
      <PlayButtonImage alt="button" src={currentSrc} isPlaying={isPlaying} />
    </PlayButtonBlock>
  );
};

export default PlayButton;
