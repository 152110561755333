import React, { useRef, useState, useEffect, useContext } from "react";
import { AppAudio, AppBackground, AppBlock, AppBody, AppCanvas, AppContent, AppText } from "./AppStyles";
import Container from "./components/Container/Container";
import Header from "./components/Header/Header";
import PlayButton from "./components/PlayButton/PlayButton";
import { ChannelsContext } from "./contexts/channels";
import WebApp from "./utils/webapp";

function App() {
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const { channel } = useContext(ChannelsContext);
  const canvas = useRef<HTMLCanvasElement | null>(null);
  const audioSource = useRef<MediaElementAudioSourceNode | null>(null);
  const audioAnalyser = useRef<AnalyserNode | null>(null);
  const frameId = useRef<number | null>(null);
  const audio = useRef<HTMLAudioElement | null>(null);

  useEffect(() => {
    if (!audio.current) {
      return;
    }

    const audioCtx = new AudioContext();

    const source = audioCtx.createMediaElementSource(audio.current);
    const analyser = audioCtx.createAnalyser();

    source.connect(analyser);
    analyser.connect(audioCtx.destination);

    audioSource.current = source;
    audioAnalyser.current = analyser;
  }, []);

  function onPlay(e: React.SyntheticEvent<HTMLAudioElement, Event>) {
    if (!canvas.current || !audioAnalyser.current) {
      return;
    }

    const ctx = canvas.current.getContext("2d");

    if (frameId.current) {
      cancelAnimationFrame(frameId.current);
    }

    function animate() {
      if (!ctx || !canvas.current || !audio.current || !channel || !audioAnalyser.current) {
        return;
      }

      const frequencyData = new Uint8Array(audioAnalyser.current.frequencyBinCount);

      audioAnalyser.current.getByteFrequencyData(frequencyData);

      ctx.clearRect(0, 0, canvas.current.width, canvas.current.height);

      ctx.fillStyle = channel.animationLineColor;

      for (let i = 0; i < audioAnalyser.current.frequencyBinCount / 4; i++) {
        ctx.fillRect(i * 5, canvas.current.height / 2, 2.5, -(frequencyData[i] / 6) * 1.1);
        ctx.fillRect(i * 5, canvas.current.height / 2, 2.5, (frequencyData[i] / 6) * 1.1);
      }

      frameId.current = requestAnimationFrame(animate);
    }

    requestAnimationFrame(animate);
  }

  async function changeIsPlaying() {
    if (!audio.current) {
      return;
    }

    isPlaying ? audio.current.pause() : await audio.current.play();
    setIsPlaying((prev) => !prev);
  }

  useEffect(() => {
    WebApp.expand();
    WebApp.ready();
  }, []);

  useEffect(() => {
    if (!audio.current) {
      return;
    }

    setIsPlaying(false);
    audio.current.pause();
  }, [channel]);

  return (
    <AppBlock backgroundColor={channel?.backgroundColor ?? "white"}>
      <Header />
      <AppContent>
        <Container>
          <AppBody>
            <AppAudio ref={audio} onPlay={onPlay} controls src={channel?.streamUrl} crossOrigin="anonymous" />
            <AppBackground alt="background" src={channel?.backgroundUrl} />
            <AppCanvas ref={canvas} />
            <PlayButton isPlaying={isPlaying} onClick={changeIsPlaying} color={channel?.animationLineColor ?? "red"} />
            <AppText>{channel?.description}</AppText>
          </AppBody>
        </Container>
      </AppContent>
    </AppBlock>
  );
}

export default App;
