class WebApp {
  static expand() {
    window.Telegram.WebApp.expand();
  }

  static ready() {
    window.Telegram.WebApp.ready();
  }

  static enableClosingConfirmation() {
    window.Telegram.WebApp.enableClosingConfirmation();
  }

  static disableClosingConfirmation() {
    window.Telegram.WebApp.disableClosingConfirmation();
  }

  static openLink(url: string) {
    window.Telegram.WebApp.openLink(url);
  }
}

export default WebApp;
