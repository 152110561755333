import React from "react";
import ReactDOM from "react-dom/client";
import { createGlobalStyle } from "styled-components";
import App from "./App";
import ChannelsContextProvider from "./contexts/channels";

const GlobalStyles = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
  * {
    box-sizing: border-box;
    border: none;
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
  }

  body, html {
    width: 100%;
    height: 100%;
    font-size: 16px;
    // @media (max-width: 375px) {
    //   font-size: 15px;
    // }
    // @media (max-width: 360px) {
    //   font-size: 14.5px;
    // }
    // @media (max-width: 340px) {
    //   font-size: 13.5px;
    // }
    @media (max-width: 440px) {
      font-size: 14.5px;
    }
    @media (max-width: 400px) {
      font-size: 14px;
    }
    @media (max-width: 380px) {
      font-size: 13px;
    }
    @media (max-width: 340px) {
      font-size: 12px;
    }
    @media (max-width: 320px) {
      font-size: 11.5px;
    }
  }

  #root {
    min-height: 100vh;
    height: 100%;
    width: 100%;
  }

  a {
    text-decoration: none;
  }

  input {
    outline: none
  }
`;

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <ChannelsContextProvider>
    <GlobalStyles />
    <App />
  </ChannelsContextProvider>
);
