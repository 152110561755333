import { createContext, FC, PropsWithChildren, useState, useEffect } from "react";
import ChannelsService from "../services/channelsService";
import IChannel from "../types/channel";

export interface IChannelsContext {
  channel: IChannel | null;
  channels: IChannel[];
  changeChannel(channel: IChannel): any;
  changeChannels(channels: IChannel[]): any;
}

export const ChannelsContext = createContext<IChannelsContext>({
  channel: null,
  channels: [],
  changeChannel() {},
  changeChannels() {},
});

const ChannelsContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [channels, setChannels] = useState<IChannel[]>([]);
  const [channel, setChannel] = useState<IChannel | null>(null);

  useEffect(() => {
    async function getData() {
      const channels = await ChannelsService.getChannels();
      setChannel(channels.length ? channels[0] : null);
      setChannels(channels);
    }

    getData();
  }, []);

  const changeChannel = (channel: IChannel) => setChannel(channel);

  const changeChannels = (channels: IChannel[]) => setChannels(channels);

  return <ChannelsContext.Provider value={{ channel, channels, changeChannel, changeChannels }}>{children}</ChannelsContext.Provider>;
};

export default ChannelsContextProvider;
