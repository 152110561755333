import styled from "styled-components";
import pxIntoRem from "../../utils/pxIntoRem";

const HeaderBlock = styled.header`
  width: 100%;
  z-index: 999;
`;

const HeaderBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: ${pxIntoRem(25)} 0px;
`;

const HeaderLogo = styled.div`
  display: flex;
  align-items: center;
`;

const HeaderLogoImage = styled.img`
  width: ${pxIntoRem(56)};
  height: ${pxIntoRem(70)};
`;

const HeaderLogoContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: ${pxIntoRem(12)};
`;

const HeaderLogoTitle = styled.h2`
  font-size: ${pxIntoRem(13)};
  letter-spacing: ${pxIntoRem(0.4)};
  color: #032830;
`;

const HeaderLogoOnline = styled.span`
  font-size: ${pxIntoRem(10)};
  letter-spacing: ${pxIntoRem(1.1)};
  font-weight: 600;
  line-height: ${pxIntoRem(10)};
  color: #032830;
`;

interface IHeaderLogoLine {
  color: string;
}

const HeaderLogoLine = styled.div<IHeaderLogoLine>`
  width: ${pxIntoRem(76)};
  height: ${pxIntoRem(2)};
  background-color: ${({ color }) => color};
  margin-top: ${pxIntoRem(2)};
`;

interface IHeaderLogoStation {
  color: string;
}

const HeaderLogoStation = styled.span<IHeaderLogoStation>`
  font-size: ${pxIntoRem(16)};
  font-weight: 600;
  letter-spacing: ${pxIntoRem(0.45)};
  color: ${({ color }) => color};
`;

export { HeaderBlock, HeaderBody, HeaderLogo, HeaderLogoImage, HeaderLogoLine, HeaderLogoOnline, HeaderLogoStation, HeaderLogoContent, HeaderLogoTitle };
