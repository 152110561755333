import styled from "styled-components";
import pxIntoRem from "./utils/pxIntoRem";

interface IAppBlock {
  backgroundColor: string;
}

const AppBlock = styled.div<IAppBlock>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

const AppContent = styled.div`
  display: flex;
  width: 100%;
  flex-grow: 1;
`;

const AppBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const AppAudio = styled.audio`
  display: none;
`;

const AppBackground = styled.img`
  width: 100%;
  height: ${pxIntoRem(650)};
  position: absolute;
  top: ${pxIntoRem(55)};
  left: 0px;
  object-fit: cover;
  object-position: left;
`;

const AppCanvas = styled.canvas`
  position: absolute;
  top: ${pxIntoRem(406)};
  left: 0px;
  width: 100%;
  height: ${pxIntoRem(112)};
`;

const AppText = styled.p`
  display: block;
  font-size: ${pxIntoRem(16)};
  font-weight: 500;
  letter-spacing: ${pxIntoRem(0.4)};
  color: #032830;
  margin-top: ${pxIntoRem(530)};
  margin-bottom: ${pxIntoRem(30)};
  z-index: 99;
`;

export { AppBlock, AppContent, AppBody, AppBackground, AppCanvas, AppAudio, AppText };
