import { FC, useContext } from "react";
import { ChannelsContext } from "../../contexts/channels";
import Container from "../Container/Container";
import HeaderBurger from "./HeaderBurger/HeaderBurger";
import { HeaderBlock, HeaderBody, HeaderLogo, HeaderLogoContent, HeaderLogoImage, HeaderLogoLine, HeaderLogoOnline, HeaderLogoStation, HeaderLogoTitle } from "./HeaderStyles";

const Header: FC = () => {
  const { channel } = useContext(ChannelsContext);
  return (
    <HeaderBlock>
      <Container>
        <HeaderBody>
          <HeaderLogo>
            <HeaderLogoImage alt="logo" src="/images/logo.png" />
            <HeaderLogoContent>
              <HeaderLogoTitle>GRABSTER</HeaderLogoTitle>
              <HeaderLogoOnline>online</HeaderLogoOnline>
              <HeaderLogoLine color={channel?.animationLineColor ?? ""} />
              <HeaderLogoStation color={channel?.animationLineColor ?? ""}>{channel?.title}</HeaderLogoStation>
            </HeaderLogoContent>
          </HeaderLogo>
          <HeaderBurger />
        </HeaderBody>
      </Container>
    </HeaderBlock>
  );
};

export default Header;
