import styled from "styled-components";
import pxIntoRem from "../../../utils/pxIntoRem";

const HeaderBurgerBlock = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: transparent;
  position: relative;
  top: ${pxIntoRem(-11)};
  cursor: pointer;
`;

interface IHeaderBurgerLine {
  color: string;
}

const HeaderBurgerLine = styled.div<IHeaderBurgerLine>`
  height: ${pxIntoRem(4)};
  width: ${pxIntoRem(34)};
  background-color: ${({ color }) => color};
  margin-top: ${pxIntoRem(5)};
  border-radius: ${pxIntoRem(5)};
  &:first-of-type {
    margin-top: 0px;
  }
`;

const HeaderBurgerContent = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  &::after {
    content: "";
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #032830;
    opacity: 0.95;
  }
`;

const HeaderBurgerContentBody = styled.div`
  width: 100%;
  height: 100%;
  padding: ${pxIntoRem(28)} 0px ${pxIntoRem(28)} 0px;
  z-index: 99999;
  position: relative;
`;

const HeaderBurgerClose = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  cursor: pointer;
  width: ${pxIntoRem(34)};
  height: ${pxIntoRem(22)};
  background-color: transparent;
`;

const HeaderBurgerCloseLine = styled.div`
  position: absolute;
  width: ${pxIntoRem(30)};
  height: ${pxIntoRem(3)};
  background-color: white;
  &:first-of-type {
    transform: rotate(45deg);
  }
  &:last-of-type {
    transform: rotate(-45deg);
  }
`;

const HeaderBurgerItem = styled.div`
  display: flex;
  margin-top: ${pxIntoRem(38)};
  cursor: pointer;
  &:first-of-type {
    margin-top: 0px;
  }
`;

const HeaderBurgerItemImageBlock = styled.div`
  width: ${pxIntoRem(130)};
  height: ${pxIntoRem(130)};
  background-color: white;
  flex-shrink: 0;
`;

const HeaderBurgerItemImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const HeaderBurgerItemTextBlock = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: ${pxIntoRem(28)};
`;

const HeaderBurgerItemTitle = styled.h2`
  font-size: ${pxIntoRem(21)};
  letter-spacing: ${pxIntoRem(0.2)};
  color: white;
  font-weight: 600;
  margin-top: ${pxIntoRem(11)};
`;

const HeaderBurgerItemDescription = styled.p`
  font-size: ${pxIntoRem(12)};
  letter-spacing: ${pxIntoRem(-0.35)};
  color: white;
  font-weight: 600;
  margin-top: ${pxIntoRem(28)};
  line-height: ${pxIntoRem(13.5)};
`;

export {
  HeaderBurgerLine,
  HeaderBurgerBlock,
  HeaderBurgerContent,
  HeaderBurgerContentBody,
  HeaderBurgerItem,
  HeaderBurgerItemImage,
  HeaderBurgerItemImageBlock,
  HeaderBurgerItemTextBlock,
  HeaderBurgerItemDescription,
  HeaderBurgerItemTitle,
  HeaderBurgerCloseLine,
  HeaderBurgerClose,
};
