import styled from "styled-components";
import pxIntoRem from "../../utils/pxIntoRem";

interface IPlayButtonBlock {
  color: string;
}

const PlayButtonBlock = styled.button<IPlayButtonBlock>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${pxIntoRem(80)};
  height: ${pxIntoRem(80)};
  border-radius: 100%;
  background-color: ${({ color }) => color};
  cursor: pointer;
  // z-index: 999;
  position: absolute;
  top: ${pxIntoRem(515)};
`;

interface IPlayButtonImage {
  isPlaying: boolean;
}

const PlayButtonImage = styled.img<IPlayButtonImage>`
  width: ${pxIntoRem(48)};
  ${({ isPlaying }) =>
    !isPlaying &&
    `
    position: relative;
    right: ${pxIntoRem(-3)};
  `}
`;

export { PlayButtonBlock, PlayButtonImage };
