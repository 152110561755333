import { useEffect, useState, useContext, FC } from "react";
import { ChannelsContext } from "../../../contexts/channels";
import ChannelsService from "../../../services/channelsService";
import IChannel from "../../../types/channel";
import Container from "../../Container/Container";
import {
  HeaderBurgerBlock,
  HeaderBurgerClose,
  HeaderBurgerCloseLine,
  HeaderBurgerContent,
  HeaderBurgerContentBody,
  HeaderBurgerItem,
  HeaderBurgerItemDescription,
  HeaderBurgerItemImage,
  HeaderBurgerItemImageBlock,
  HeaderBurgerItemTextBlock,
  HeaderBurgerItemTitle,
  HeaderBurgerLine,
} from "./HeaderBurgerStyles";

const HeaderBurger: FC = () => {
  const [isContentShown, setIsContentShown] = useState(false);
  const { channels, channel, changeChannel } = useContext(ChannelsContext);

  const changeContentShown = () => setIsContentShown((prev) => !prev);

  function changeCurrentChannel(channel: IChannel) {
    changeChannel(channel);
    setIsContentShown(false);
  }

  return (
    <>
      <HeaderBurgerBlock onClick={changeContentShown}>
        <HeaderBurgerLine color={channel?.animationLineColor ?? "red"} />
        <HeaderBurgerLine color={channel?.animationLineColor ?? "red"}/>
        <HeaderBurgerLine color={channel?.animationLineColor ?? "red"}/>
      </HeaderBurgerBlock>

      {isContentShown && (
        <HeaderBurgerContent>
          <Container>
            <HeaderBurgerContentBody>
              <HeaderBurgerClose onClick={changeContentShown}>
                <HeaderBurgerCloseLine />
                <HeaderBurgerCloseLine />
              </HeaderBurgerClose>
              {channels.map((channel) => {
                return (
                  <HeaderBurgerItem key={channel.id} onClick={() => changeCurrentChannel(channel)}>
                    <HeaderBurgerItemImageBlock>
                      <HeaderBurgerItemImage alt="channel_image" src={channel.miniatureUrl} />
                    </HeaderBurgerItemImageBlock>
                    <HeaderBurgerItemTextBlock>
                      <HeaderBurgerItemTitle>{channel.title}</HeaderBurgerItemTitle>
                      <HeaderBurgerItemDescription>{channel.description}</HeaderBurgerItemDescription>
                    </HeaderBurgerItemTextBlock>
                  </HeaderBurgerItem>
                );
              })}
            </HeaderBurgerContentBody>
          </Container>
        </HeaderBurgerContent>
      )}
    </>
  );
};

export default HeaderBurger;
